<template>
  <div class="main-container">
    <!-- 面包屑 -->
    <div class="question-nav">
      <router-link :to="{name:'Question'}">问答</router-link>
      <span class="slash">></span>
      <span class="current">支付问答</span>
    </div>
    <div class="success">
      <div>
        <img src="../../images/pay-success.png" />
      </div>
      <div class="title">支付成功!</div>
      <router-link :to="{name:'QuestionShow',params:{id:$route.params.ask_id}}">查看问答</router-link>
      <div class="timer">
        <span>
          <span>{{second}}</span>
          s秒后自动跳转到原问答页面
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "QuestionSuccess",
  data () {
    return {
      second: 3,
      timer: 0
    };
  },
  created () {
    let type = this.$route.query.type;
    this.timer = setInterval(() => {
      let second = this.second;
      if (second == 0) {
        clearInterval(this.timer);
        this.$router.push({
          name: 'QuestionShow',
          params: { id: this.$route.params.ask_id }
        });
      } else {
        second--;
        this.second = second;
      }
    }, 1000);
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../css/question.scss";
.success {
  background-color: #fff;
  min-height: 380px;
  text-align: center;
  padding-top: 150px;
  .title {
    font-size: 24px;
    color: #333333;
    margin-top: 24px;
  }
  /deep/ a {
    margin-top: 32px;
    display: inline-block;
    color: #fff;
    width: 148px;
    height: 49px;
    line-height: 49px;
    background: $primary_color;
    border-radius: 5px;
  }
}

.timer {
  padding: 22px 0;
  text-align: center;
  color: #999999;
  font-size: 14px;
}
</style>
